import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import React, { createContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {

    const firebaseConfig = {
        apiKey: "AIzaSyCKJP3045hVG35w_mZivTOixACkwCiUs2c",
        authDomain: "support-mac.firebaseapp.com",
        databaseURL:
          "https://support-mac-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "support-mac",
        storageBucket: "support-mac.appspot.com",
        messagingSenderId: "784137443921",
        appId: "1:784137443921:web:50365d526968e44a7a3670",
        measurementId: "G-0HB2CSPMQF",
      };

      const notify = () => toast('Product added to Cart');

      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      const [cartItems, setCartItems] = useState(() => {
        // Load cart items from localStorage if available
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
      });
    
      useEffect(() => {
        // Save cart items to localStorage whenever they change
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
      }, [cartItems]);

  

      const addToCart = async (newItem) => {
        let imageUrl = await fetchImage(newItem);
        newItem["imageUrl"] = imageUrl;
      
        setCartItems((prevItems) => {
          // Check if the item already exists in the cart
          const itemExists = prevItems.some(item => item.id === newItem.id);
      
          // If the item doesn't exist, add it to the cart
          if (!itemExists) {
            return [...prevItems, newItem];
          }
          toast('Product added to Cart')
          // If the item exists, return the previous cart items unchanged
          return prevItems;
        });
      };
  
  const fetchImage = async (product) => {
    const storage = getStorage();

    const imagePath = `${product.category}/${product.name}/`;

    const listRef = ref(storage, imagePath);

    try {
      const result = await listAll(listRef);

      if (result.items.length > 0) {
        const url = await getDownloadURL(result.items[0]);

        return url;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  return (
    <CartContext.Provider value={{ cartItems, addToCart , setCartItems }}>
      {children}
    </CartContext.Provider>
  );
};